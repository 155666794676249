<template>
  <v-container pa-0 fluid v-resize="onResize">
    <v-layout row nowrap justify-start align-center fill-height>
      <v-flex shrink :xs1="!navIsExtended">
        <v-navigation-drawer
          id="settings-nav-bar"
          v-model="drawer"
          hide-overlay
          disable-route-watcher
          permanent
          :style="navStyle"
          :mini-variant.sync="isMini"
          @mouseleave.native="setMini(true)"
          @mouseover.native="setMini(false)"
        >
          <v-toolbar flat>
            <v-list clas="pa-0">
              <v-list-tile>
                <v-list-tile-avatar>
                  <v-icon>fal fa-cog</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <h2>Settings</h2>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-toolbar>
          <v-divider></v-divider>
          <v-list>
            <v-list-tile
              v-for="setting in settingsList"
              :key="setting.title"
              @click="to(setting.route)"
              :data-cy="setting.route"
            >
              <v-list-tile-action>
                <v-icon :color="activeRoute(setting.route) ? 'primary' : ''">fal fa-{{ setting.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title :class="{ 'primary--text': activeRoute(setting.route) }">{{ setting.name }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex style="height: 100%">
        <v-btn @click="setNavExtended" icon style="position: absolute;">
          <v-scale-transition>
            <v-icon key="extended" medium v-if="navIsExtended" color="purple">fal fa-angle-double-left</v-icon>
            <v-icon key="mini" medium v-else color="purple">fal fa-angle-double-right</v-icon>
          </v-scale-transition>
        </v-btn>
        <v-fade-transition mode="out-in">
          <router-view :class="{ 'pt-5' : this.$vuetify.breakpoint.smAndDown }" :style="{ 'max-width': maxRouteWidth }" v-bind:search="search"></router-view>
        </v-fade-transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'settings',
  computed: {
    activeSettings () {
      const activeSettings = []
      for (const config of this.userConfigurations) {
        if (config.active) activeSettings.push(config)
      }
      for (const config of this.companyConfigurations) {
        if (config.active) activeSettings.push(config)
      }
      return activeSettings
    },

    navStyle () {
      return {
        height: 'calc(100vh - 32px)',
        position: this.navIsExtended ? 'relative' : 'absolute'
      }
    },

    settingsList () {
      return [...this.userConfigurations, ...this.companyConfigurations]
    },

    maxRouteWidth () {
      const sideBarWidth = document.querySelector('.t-sidebar')?.getBoundingClientRect()?.width ?? 0
      const buffer = this.$vuetify.breakpoint.smAndDown ? 50 : 0
      return (this.width - (this.navBarWidth + sideBarWidth + buffer)) + 'px'
    }
  },
  watch: {
    activeSettings: function (value) {
      if (value.length === 1) {
        this.to(value[0].route)
      }
    }
  },
  data () {
    return {
      drawer: null,
      isMini: true,
      navIsExtended: false,
      navBarWidth: 0,
      search: '',
      userConfigurations: [
        {
          name: 'My Account',
          route: 'account-management',
          icon: 'user-cog',
          subSettings: [
            'user info',
            'change password',
            'employee profile'
          ],
          active: true
        },
        {
          name: 'User Management',
          route: 'user-management',
          icon: 'users-crown',
          subSettings: [''],
          active: true
        }
      ],

      companyConfigurations: [
        {
          name: 'Company Settings',
          route: 'company-settings',
          icon: 'cogs',
          subSettings: [''],
          active: true
        },
        {
          name: 'Logistics',
          route: 'logistics',
          icon: 'truck',
          subSettings: [
            'carrier accounts',
            'carriers',
            'company addresses',
            'carrier services'
          ],
          active: true
        }
      ],

      integrationConfigurations: [],
      width: '',

      activeColor: 'blue lighten-1'
    }
  },
  methods: {
    setMini (mini) {
      if (!this.navIsExtended) {
        this.isMini = mini
      }
    },

    onResize () {
      this.width = window.innerWidth
    },

    setNavExtended () {
      this.isMini = this.navIsExtended
      this.navIsExtended = !this.navIsExtended
      setTimeout(() => {
        this.navBarWidth = document.querySelector('#settings-nav-bar')?.getBoundingClientRect()?.width ?? 0
      }, 100)
    },

    activeRoute (route) {
      return this.$route.fullPath.includes(route)
    },

    filteredConfig (config) {
      return config.filter(c => {
        const r = c.subSettings.findIndex(s => s.indexOf(this.search) > -1) > -1
        c.active = r
        return r
      })
    },

    to (route) {
      if (!this.$route.fullPath.includes(route)) {
        this.$router.push(`/settings/${route}`)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const navBarWidth = document.querySelector('#settings-nav-bar')?.getBoundingClientRect()?.width ?? 0
      this.navBarWidth = navBarWidth + 'px'
    })
  }
}
</script>

<style scoped>
  .settings-button:hover {
    background-color: #EEEEEE;
  }

  .settings-button {
    cursor: pointer;
    width: 100%;
  }

  .settings-button-container {
    max-height: calc(100vh - 225px);
    overflow-y: auto;
  }
</style>
